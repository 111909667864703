import React, {useState, useEffect} from 'react';
import './App.css';
import './Slider.css';
import SvgLogo from "./logo";
import palette from 'nice-color-palettes/1000';
import Slider from "./Slider";
import {defaultCoords} from "./logo";
import calculate from "./logoCalculator";
import ColorPicker from './ColorPicker'

function App() {
    const [showBarsSettings, setShowBarsSettings] = useState(false);
    const [barsCount, setBarsCount] = useState(4);
    const [barsPadding, setBarsPadding] = useState(40);
    const [barsGap, setBarsGap] = useState(27);
    const [smallBarWidth, setSmallBarWidth] = useState(53);


    const barsConfig = calculate(barsCount, barsPadding, barsGap, smallBarWidth);

    const [showSlider, setShowSlider] = useState(false);
    const [colorIndex, setColorIndex] = useState(-1);
    const [coords, setCoords] = useState(defaultCoords);
    const updateCoordsByIndex = (i, val) => {
        const coordsTemp = [...coords];
        coordsTemp[i] = val;

        setCoords(coordsTemp)

        console.log(coordsTemp.join(','))
    }

    const updateBarsCount = (c) => {
        setBarsCount(Math.min(Math.max(c, 4), 6));
    };

    const [colors, setColors] = useState();
    const [colorPalette, setColorPalette] = useState();
    useEffect(() => {
        const colors = colorIndex < 0 || !palette[colorIndex] ? undefined : palette[colorIndex];
        if(colors){
            const rc = [...colors, ...colors].slice(0, barsCount + 1);
            setColors(rc.join(','));
            setColorPalette(rc)
        }else {
            setColors(undefined);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [colorIndex])

    useEffect(() => {
        if(!colors){
            return
        }
        const colorsArray = colors.split(',');
        const len = colorsArray.length;

        if(len < barsCount + 1){
            const rc = [...colorsArray, ...colorsArray].slice(0, barsCount + 1);
            setColors(rc.join(','));
        }
    }, [colors, barsCount])

    const [showColorPicker, setShowColorPicker] = useState(false);


    return (
        <div className="App">
            <section className="controls">

                {showSlider ? <Slider onChange={updateCoordsByIndex} count={barsConfig.count} /> : null}

                <button onClick={() => {
                    setColorIndex((i) => i - 1)
                }}>Prev
                </button>
                <input type="number" value={colorIndex} onChange={(event) => {
                    setColorIndex(parseInt(event.target.value))
                }}/>
                <button onClick={() => {
                    setColorIndex((i) => i + 1)
                }}>Next
                </button>
                &nbsp;
                <button onClick={() => {
                    setShowSlider((s) => !s)
                }}>Sliders
                </button>
                <button onClick={() => {
                    setShowBarsSettings((s) => !s)
                }}>Settings
                </button>
                <button onClick={() => {
                    setShowColorPicker((s) => !s)
                }}>Colors
                </button>

                {showBarsSettings ? (
                    <div className="settings">
                        Count: <input style={{width: '40px'}} value={barsCount} type="number"
                               onChange={(event => updateBarsCount(parseInt(event.target.value)))}/>
                        Padding px: <input style={{width: '40px'}} value={barsPadding} type="number"
                               onChange={(event => setBarsPadding(parseInt(event.target.value)))}/>
                       Gap % of width: <input style={{width: '40px'}} value={barsGap} type="number"
                               onChange={(event => setBarsGap(parseInt(event.target.value)))}/>
                       Small bars width % of width: <input style={{width: '40px'}} value={smallBarWidth} type="number"
                               onChange={(event => setSmallBarWidth(parseInt(event.target.value)))}/>
                    </div>
                ) : null}

                {showColorPicker ? (
                    <div className="colors">
                        <ColorPicker colors={colors} setColors={setColors} count={barsCount + 1} colorPalette={colorPalette} />
                    </div>
                ) : null}

            </section>
            <section className="App-header">
                <div className="row">
                    <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={32} height={32}/>
                    <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={100} height={100}/>
                    <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={256} height={256}/>
                </div>


                <div className="row">
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={16} height={16} bars={false}/>
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={32} height={32} bars={false}/>
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={100} height={100} bars={false}/>
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={256} height={256} bars={false}/>
            </div>

            <div className="row grayFilter">
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={16} height={16} bars={false}/>
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={32} height={32} bars={false}/>
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={100} height={100}/>
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={256} height={256}/>
            </div>
        </section>

        <section className="App-header App-header-Light">
            <div className="row">
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={32} height={32}/>
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={100} height={100}/>
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={256} height={256}/>
            </div>
            <div className="row">
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={16} height={16} bars={false}/>
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={32} height={32} bars={false}/>
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={100} height={100} bars={false}/>
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={256} height={256} bars={false}/>
            </div>
            <div className="row grayFilter">
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={16} height={16} bars={false}/>
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={32} height={32} bars={false}/>
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={100} height={100}/>
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={256} height={256}/>
            </div>
        </section>

        <section className="App-header">
            <div className="row">
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={32} height={32} bg={false}/>
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={100} height={100}  bg={false}/>
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={256} height={256}  bg={false}/>
            </div>
            <div className="row">
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={16} height={16} bars={false}  bg={false}/>
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={32} height={32} bars={false}  bg={false}/>
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={100} height={100} bars={false}  bg={false}/>
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={256} height={256} bars={false}  bg={false}/>
            </div>
            <div className="row grayFilter">
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={16} height={16} bars={false}  bg={false}/>
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={32} height={32} bars={false}  bg={false}/>
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={100} height={100}  bg={false}/>
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={256} height={256}  bg={false}/>
            </div>
        </section>

        <section className="App-header App-header-Light">
            <div className="row">
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={32} height={32}  bg={false} />
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={100} height={100} bg={false} />
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={256} height={256} bg={false} />
            </div>
            <div className="row">
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={16} height={16} bars={false} bg={false} />
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={32} height={32} bars={false} bg={false} />
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={100} height={100} bars={false} bg={false} />
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={256} height={256} bars={false} bg={false} />
            </div>
            <div className="row grayFilter">
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={16} height={16} bars={false} bg={false} />
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={32} height={32} bars={false} bg={false} />
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={100} height={100} bg={false} />
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={256} height={256} bg={false} />
            </div>
        </section>



        <section className="App-header">
            <div className="row">
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={32} height={32} bg={true} bgColorPretty={true} />
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={100} height={100}  bg={true} bgColorPretty={true} />
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={256} height={256}  bg={true} bgColorPretty={true} />
            </div>
            <div className="row">
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={16} height={16} bars={false}  bg={true} bgColorPretty={true} />
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={32} height={32} bars={false}  bg={true} bgColorPretty={true} />
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={100} height={100} bars={false}  bg={true} bgColorPretty={true} />
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={256} height={256} bars={false}  bg={true} bgColorPretty={true} />
            </div>
            <div className="row grayFilter">
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={16} height={16} bars={false}  bg={true} bgColorPretty={true} />
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={32} height={32} bars={false}  bg={true} bgColorPretty={true} />
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={100} height={100}  bg={true} bgColorPretty={true} />
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={256} height={256}  bg={true} bgColorPretty={true} />
            </div>
        </section>

        <section className="App-header App-header-Light">
            <div className="row">
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={32} height={32}  bg={true} bgColorPretty={true} />
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={100} height={100} bg={true} bgColorPretty={true} />
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={256} height={256} bg={true} bgColorPretty={true} />
            </div>
            <div className="row">
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={16} height={16} bars={false} bg={true} bgColorPretty={true} />
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={32} height={32} bars={false} bg={true} bgColorPretty={true} />
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={100} height={100} bars={false} bg={true} bgColorPretty={true} />
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={256} height={256} bars={false} bg={true} bgColorPretty={true} />
            </div>
            <div className="row grayFilter">
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={16} height={16} bars={false} bg={true} bgColorPretty={true} />
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={32} height={32} bars={false} bg={true} bgColorPretty={true} />
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={100} height={100} bg={true} bgColorPretty={true} />
                <SvgLogo barsConfig={barsConfig} coords={coords} colors={colors} width={256} height={256} bg={true} bgColorPretty={true} />
            </div>
        </section>


    </div>
  );
}

export default App;
