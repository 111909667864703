import React, {useState} from 'react';
import {SketchPicker} from 'react-color';
import './ColorPicker.css';

const ColorPicker = (props) => {

    const {count = 4, colors: colorString, setColors, colorPalette} = props;

    const dummy = Array.apply(null, Array(count)).map(function () {
        return '#ccc';
    });

    const colors = colorString ? colorString.split(',') : dummy;

    const [index, setIndex] = useState();

    const colorUpdate = (index, color) => {
        const colorsUpdate = [...colors];
        colorsUpdate[index] = color;
        setColors(colorsUpdate.join(','));
    };

    const popover = {
        position: 'absolute',
        zIndex: '2',
        left: '50px',
        top: '50px',
    }
    const cover = {
        position: 'fixed',
        top: '0px',
        right: '0px',
        bottom: '0px',
        left: '0px',
    }

    const picker = index !== undefined ?
        (<div style={popover}>
            <div style={cover} onClick={() => setIndex(undefined)}/>
            <SketchPicker
                color={colors[index] || '#ccc'}
                //onChangeComplete={() => setIndex(undefined)}
                onChange={(color) => colorUpdate(index, color.hex)}
                presetColors={colorPalette}
            /></div>)
        : null;

    return <div className="ColorPickerContainer">
        {colors.map((c, i) => {
            return <div key={String(i)} className="item" style={{backgroundColor: c}}
                        onClick={() => setIndex(index === i ? undefined : i)}>{c}</div>
        })}

        {picker}
    </div>
};

export default ColorPicker;
