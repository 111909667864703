import * as React from "react";
import PropTypes from 'prop-types';

export const defaultCoords = [
  [207, 256, 427, 473],
  [242, 290, 454, 486],
  [170, 212, 399, 431],
  [49, 82, 442, 481],
];

function SvgLogo(props) {

  const {
    bars = true,
    bg = true,
    bgColor = '#000000',
    colors = '#d65db1,#ff6f91,#ff9671,#ffc75f,#000',
    bgColorPretty = false,
    coords = defaultCoords,
    barsConfig
  } = props;

  const {width, height} = props;

  const {
    count,
    padding,
    barWidth,
    gap,
    smallBarWidth,
    smallBarShift
  } = barsConfig;

  const dummy = Array.apply(null, Array(count)).map(function () {
      return null;
  });

  const barColor = colors.split(',');

  return (
      <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 512 512"
          width="1em"
          height="1em"
          {...{width, height}}
      >
        {bg ? (<g data-name="Layer 3">
              <path d="M.5.5h511v511H.5z" fill={bgColorPretty ? barColor[count] : bgColor}/>
            </g>)
            : null}
        <g data-name="Layer 1">
          {bars ?
              <g data-name="Layer 4" fill="#666">
                {dummy.map((v, index) => {
                  const x = padding + (barWidth + gap) * index + smallBarShift;
                  const coordsIndex = index % coords.length;

                  return <rect key={index} x={x} y={coords[coordsIndex][0]} width={smallBarWidth} height={coords[coordsIndex][3] - coords[coordsIndex][0]}/>;
                })}
              </g> : null}
          <g data-name="BarsMain">
            {dummy.map((v, index) => {
              const x = padding + (barWidth + gap) * index;
              const coordsIndex = index % coords.length;
              const colorIndex = index % barColor.length;

              return <rect key={index} x={x} y={coords[coordsIndex][1]} width={barWidth} height={coords[coordsIndex][2] - coords[coordsIndex][1]}
                           fill={barColor[colorIndex]}/>;
            })}
          </g>
        </g>
      </svg>
  );
}

SvgLogo.propTypes = {
  bars: PropTypes.bool,
  bg: PropTypes.bool,
  bgColor: PropTypes.string,
  bgColorPretty: PropTypes.bool,
  colors: PropTypes.string,
  coords: PropTypes.arrayOf(PropTypes.arrayOf(PropTypes.number)),
};

export default SvgLogo;

