import React from "react";
import ReactSlider from "react-slider";
import {defaultCoords} from "./logo";

const Slider = (props) => {

    const defaultValues = defaultCoords;

    const update = (i, val) => {
        props.onChange(i, val);
    };

    const dummy = Array.apply(null, Array(props.count)).map(function () {
        return null;
    });


    return (
        <div className="sliders">
            {dummy.map((v, index) => {

                const coordsIndex = index % defaultValues.length;

                return <ReactSlider
                    key={index}
                    className="vertical-slider"
                    thumbClassName="example-thumb"
                    trackClassName="example-track"
                    defaultValue={defaultValues[coordsIndex]}
                    renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
                    orientation="vertical"
                    onChange={(val) => {
                        update(index, val);
                    }}
                    // invert
                    pearling
                    minDistance={0}
                    max={512}
                />
            })}

        </div>);
}

export default Slider;
