const imageWidth = 512;

export default function calculate(count, paddingPx, gapPercent, smallWidthPercent) {

    const baseWidth = imageWidth - paddingPx * 2;

    const gapShare = gapPercent/100;

    const barWidth = baseWidth / (count + (count-1) * gapShare);
    const gap = barWidth * gapShare;
    const smallBarWidth = barWidth * smallWidthPercent / 100;
    const smallBarShift = (barWidth - smallBarWidth) / 2;

    return {
        count,
        padding: paddingPx,
        barWidth,
        gap,
        smallBarWidth,
        smallBarShift
    };
};
